<template>
    <div>
        <div class="bg-gray" v-b-toggle="uuid">
            <b-popover :target="`infobars-${uuid}`" triggers="hover" placement="right">
                <!-- <template #title>Popover Title</template> -->
                Distribution of Pro/Con Arguments
            </b-popover>
            <div :id="`infobars-${uuid}`" class="infobars">
                <div class="bar">
                    <div class="bar-inner" :style="proBar"></div>
                </div>
                <div class="bar barCon">
                    <div class="bar-inner" :style="conBar"></div>
                </div>
                <div class="numberContainer"><p>{{ documentInfo.sentenceCount.pro }}</p></div>
                <div class="numberContainer numberContainerCon"><p>{{ documentInfo.sentenceCount.contra }}</p></div>
            </div>
            <div class="documentTitle">
                <p class="multiline-ellipsis">
                    <a :href="documentInfo.url" target="_blank">{{ documentInfo.title }}</a>
                </p>
            </div>
            <!-- <div class="sourceAndDate">
                <p><a :href="documentInfo.url" target="_blank">{{ documentInfo.source | dropwww }} <span>/</span> {{
                    documentInfo.date | formatDate }}</a></p>
            </div> -->
            <button class="toggle-sentences"><i class="icon-arrow-down"></i></button>
        </div>
        <b-collapse :id="uuid" v-model="visible">
            <div class="collapse-content">
                <slot :name="doc"/>
            </div>
        </b-collapse>

    </div>
</template>

<script>
    let counter = 0;
    let uuid = "collapse0";

    export default {
        beforeCreate() {
            this.uuid = uuid;
            counter++;
            uuid = 'collapse' + counter;
        },

        name: "AppCollapse",
        props: {
            doc: String,
            documentInfo: Object,
            show: Boolean,
        },

        data() {
            return {
                visible: false
            }
        },

        computed: {
            proBar() {
                return 'height: ' + (100 / this.documentInfo.sentenceCount.total * this.documentInfo.sentenceCount.pro) + '%';
            },
            conBar() {
                return 'height: ' + (100 / this.documentInfo.sentenceCount.total * this.documentInfo.sentenceCount.contra) + '%';
            }

        },

        watch: {
            show() {
                this.visible = false;
            }
        }

    };
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";

    a {
        color: $white;
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    .bg-gray {
        min-width: 100%;
        height: 80px;
        padding: 0 60px 0 70px;
        color: $white;
        margin-bottom: 24px;
        position: relative;
        overflow: hidden;

        .infobars {
            width: 70px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;

            .bar {
                width: 6px;
                height: 48px;
                background-color: rgba(0, 0, 0, 0.1);
                position: absolute;
                top: 8px;
                left: 26px;

                .bar-inner {
                    width: 100%;
                    background-color: $green;
                    position: absolute;
                    bottom: 0;
                }
            }

            .barCon {
                left: 38px;

                .bar-inner {
                    background-color: $ulb-grey-green;
                }
            }

            .numberContainer {
                height: 16px;
                width: 24px;
                background-color: $white;
                position: absolute;
                left: 8px;
                bottom: 8px;

                p {
                    color: $gray;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 16px;
                    width: 24px;
                    text-align: center;
                    overflow: hidden;
                }
            }

            .numberContainerCon {
                left: 38px;
            }
        }

        .documentTitle {
            width: 100%;

            height: 80px;
            line-height: 80px;
            padding: 4px 4px 0 4px;
            margin: 0;
            float: left;

            @include media-breakpoint-up(lg) {
                padding: 4px 0 0 24px;
            }

            .multiline-ellipsis {
                overflow: hidden;
                position: relative;
                line-height: 24px;
                max-height: 48px;
                margin-right: -1em;
                padding-right: 1em;
            }
            .multiline-ellipsis:before {
                content: ' ...';
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: #62798D;
            }
            .multiline-ellipsis:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: 0.2em;
                background: #62798D;
                background-color: #62798D;
            }

            p {
                max-width: 100%;
                vertical-align: middle;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                -ms-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;

                a {
                    max-width: 100%;
                }
            }
        }

        .sourceAndDate {
            height: 80px;
            line-height: 80px;
            padding: 4px 4px 0 0;
            min-width: 80px;
            float: right;

            @include media-breakpoint-up(lg) {
                padding: 4px 24px 0 0;
            }

            p {
                vertical-align: middle;
                font-size: 16px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }

            span {
                color: $yellow;
                margin: 1px;
                font-weight: bold;
            }
        }

        .toggle-sentences {
            height: 80px;
            width: 40px;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            padding: 0;
            margin-right: 20px;
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(-180deg);
            transition: transform 0.25s;

            i {
                color: $yellow;
                font-size: 28px;
                line-height: 80px;
            }
            &:hover > i {
                color: $white;
            }
        }

        @media (min-width: 1200px) {

            .documentTitle {

                p {
                    max-width: 700px;
                }
            }
        }
    }

    .collapsed > .toggle-sentences {
        transform: unset;
    }

    .collapse-content {
        padding-top: 24px;
        padding-bottom: 32px;
    }


</style>
