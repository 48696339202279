import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import resource from 'vue-resource';

import _ from 'underscore';

import router from '@/router';
import store from '@/store';
import '@/api';
import '@/helpers/filters';
import '@/helpers/scrollto';

import App from './App.vue'
import AppHeader from './components/partials/AppHeader.vue'
import AppFooter from './components/partials/AppFooter.vue'
import AppCollapse from './components/partials/AppCollapse.vue'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nprogress/nprogress.css";
import "@/scss/style.scss";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faFile, faLink } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faFile, faLink)

Vue.use(BootstrapVue);
Vue.use(resource);
//Vue.http.options.root = '127.0.0.1:8100';
Vue.use(Vuetify)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('app-header', AppHeader);
Vue.component('app-footer', AppFooter);
Vue.component('app-collapse', AppCollapse);



new Vue({
    router,
    store,
    resource,
    components: {
        App
    },
    data: {
      isMobile : false,
        isTouch: false,
        isResized: false
    },
    methods: {
        handleResize() {
            this.isTouch = 'ontouchstart' in document.documentElement;
            this.isMobile = !window.matchMedia("(min-width: 992px)").matches;
            this.isResized = true;
            _.debounce(() => {
                this.isResized = false;
            }, 100);
        }
    },
    mounted() {
        this.handleResize();
        this.$nextTick(function() {
            window.addEventListener('resize', _.debounce(this.handleResize, 1))
        });
    },
    vuetify: new Vuetify()
}).$mount('#app');
