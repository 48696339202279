<template>
    <section class="app-tabs-content">
        <div v-if="loaded">
            <div class="container" v-if="results.error">
                <b-alert show variant="danger">
                    {{results.error }}
                </b-alert>
                <section class="bg-light-blue" id="search-form-large">
                    <search-form></search-form>
                </section>
            </div>
            <div v-else-if="results.sentences.length">
                <header class="app-tabs-nav">
                    <div class="container position-relative">
                        <div class="d-flex flex-row">
                            <SearchForm :loading="loading"></SearchForm>
                        </div>

                        <div class="d-flex flex-row" v-bind:class="{'justify-content-around': $root.isMobile}">
                            <select-box
                                    expand
                                    class="align-self-start"
                                    label="Show"
                                    :selected="currentRoute"
                                    @changed="navigateTab"
                                    :options="{
                                         results: 'Pro/con' ,
                                         results_list: 'List',
                                         results_documents: 'Documents' }">
                            </select-box>



                            <select-box
                                    class="ml-lg-auto"
                                    label="Subjects"
                                    selected=""
                                    multiple
                                    :options="results.subjects"
                                    @changed="setSubjects"></select-box>

                            <select-box
                                    class="ml-lg-1"
                                    label="Source"
                                    selected=""
                                    multiple
                                    :options="results.sources"
                                    @changed="setFilters"></select-box>
                            
                            <select-box
                                    class="ml-lg-1 width-150"
                                    label="Year"
                                    selected=""
                                    multiple
                                    :options="results.years"
                                    @changed="setYears"></select-box>

                            <select-box
                                    class="ml-lg-1 width-150"
                                    label="Sort By"
                                    :selected="sortStrategy"
                                    :options="{
                                        confidence: 'Confidence' ,
                                        timestamp: 'Date'
                                     }"
                                    @changed="setSortStrategy"></select-box>
                        </div>
                    </div>
                </header>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="search-info">
                                <strong>Found {{results.metadata.totalArguments}} arguments</strong>
                                ({{results.metadata.totalProArguments}} pro;
                                {{results.metadata.totalContraArguments}} con)
                                <strong>in {{results.metadata.numDocs}} documents</strong>
                                (classified {{results.metadata.totalClassifiedSentences}} sentences in
                                {{results.metadata.timeTotal | round}} s)
                            </p>
                        </div>
                    </div>
                    <router-view :results="results" id="results"></router-view>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Store from '@/store';

    import searchResult from '@/api/model/searchResult';
    import SearchForm from '@/components/partials/SearchForm.vue';
    import SelectBox from '@/components/partials/SelectBox.vue';

    function arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
    }

    export default {
        name: "SearchResult",
        data() {
            return {
                results: {},
                loaded: false,
                loading: false
            }
        },
        methods: {
            setData(data) {
                if (data.error || (data.sentences && data.sentences.length === 0)) {
                    this.results = {
                        error: Array.isArray(data.sentences) && data.sentences.length === 0 
                                ? "No arguments were found" 
                                : data.error 
                                    ? data.error.message || data.error
                                    : "Unknown error"
                    };
                    this.loaded = true
                    return;
                }
                this.results = {...data};
                this.results.sentences = [this.results.sentences];
                this.results.sentencesOrderedOther = this.results.sentencesOrdered;
                this.loaded = true;
            },
            navigateTab(v) {
                this.$router.push({
                    name: v,
                    query: this.$route.query
                });
            },
            setSortStrategy(v) {
                this.sortStrategy = v;
            },
            setFilters(v) {
                this.searchFilters = v;
            },
            setSubjects(v) {
                this.filterSubjects = v;
            },
            setYears (v) {
                this.filterYears = v;
            },
        },
        components: {
            SearchForm,
            SelectBox
        },
        computed: {
            sortStrategy: {
                get() {
                    return this.$store.state.searchQuery.sortStrategy
                },
                set(title) {
                    this.$store.commit('searchQuery/updateSortStrategy', title);
                },
            },
            currentRoute() {
                return this.$route.name
            },
            searchFilters: {
                get() {
                    return this.$store.state.searchQuery.searchFilters
                },
                set(v) {
                    this.$store.commit('searchQuery/updateSearchFilters', [...v]);
                },
            },
            filterSubjects: {
                get() {
                    return this.$store.state.searchQuery.filterSubjects
                },
                set(v) {
                    this.$store.commit('searchQuery/updateFilterSubjects', [...v]);
                },
            },
            sortStrategyDocuments: {
                get() {
                    return this.$store.state.searchQuery.sortStrategy
                },
                set(title) {
                    this.$store.commit('searchQuery/updateSortStrategy', title);
                },
            },
            filterYears: {
                get() {
                    return this.$store.state.searchQuery.filterYears
                },
                set(v) {
                    this.$store.commit('searchQuery/updateFilterYears', [...v]);
                },
            },
        },
        created() {
        },
        beforeRouteEnter(to, from, next) {
            Store.commit('searchQuery/updateTopic', to.query.query);
            Store.commit('searchQuery/updateSearchSubjects', to.query.subjects);

            
            searchResult.getResults({
                topic: to.query.query,
                subjects: to.query.subjects
            })
                .then((response) => {
                    next(vm => vm.setData(response));
                });
        },
        beforeRouteUpdate(to, from, next) {
            if (from.query.query != to.query.query || (to.query.subjects && !arraysEqual(from.query.subjects, to.query.subjects))) {
                this.loading = true;
                Store.commit('searchQuery/updateTopic', to.query.query);
                Store.commit('searchQuery/updateSearchSubjects', to.query.subjects);

                searchResult.getResults({
                    topic: to.query.query,
                    subjects: to.query.subjects
                })
                    .then((response) => {
                        this.setData(response);

                        this.loading = false;
                        next();
                    });
            } else {
                next();
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    .container {
        padding: 0;
    }
    ul {
        list-style: none;
        padding-left: 0;
    }

    .search-info {
        color: $gray;
        margin: 8px 0;
        font-size: 11px;
        line-height: 16px;

        @include media-breakpoint-up(md) {
            margin: 16px 0;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
        }

        @include media-breakpoint-up(lg) {
            margin: 32px 0;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
        }
    }

    .app-tabs-nav {
        background-color: $dark-green;

        height: 120px;

        @include media-breakpoint-up(lg) {
            height: 80px;
        }

        .form-control {
            background-color: $primary;
        }

        .custom-select {
            color: $white;
            width: 240px;
            height: 48px;
            padding: 0 0 0 24px;
            margin: 16px 0;
            border: 0;
            font-size: 20px;
            font-family: Signika, sans-serif;
            cursor: pointer;
            background-image: url("data:image/svg+xml;charset=utf8,<svg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='38px' height='38px' viewBox='0 0 38 38' enable-background='new 0 0 38 38' xml:space='preserve'><path id='path-1_1_' fill='%23ffca19' d='M18.667,14.896L4.552,0.781c-1.042-1.042-2.73-1.042-3.771,0c-1.042,1.041-1.042,2.729,0,3.771l16,16c1.041,1.041,2.729,1.041,3.771,0l16-16c1.041-1.042,1.041-2.729,0-3.771c-1.042-1.042-2.729-1.042-3.771,0L18.667,14.896z'/></svg>");
            background-size: 14px;
            background-position: 210px 21px;
            -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    .app-tabs-content {
        background-color: $light-blue;
        padding-bottom: 80px;
    }
</style>
