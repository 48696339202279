<template>
    <section>
        <div class="row" v-if="items.length === 2">
            <checkbox-toggle
                    v-model="showPro"
                    class="mx-auto d-lg-none"
            >
            </checkbox-toggle>
        </div>
        <div class="row col-md-12">
            <div v-for="(list, index) in currentItems" :class="columnClass" :key="index">
                <ul>
                    <search-result-card
                            v-for="sentence in list"
                            :key="sentence.id"
                            v-bind:sentence="sentence"
                            v-bind:content="sentence[textHandle]"
                            :class="blurClass"
                    ></search-result-card>
                </ul>
            </div>
        </div>
        <div class="row">
            <b-pagination size="md"
                          :total-rows="paginationCurrentTotal"
                          v-model="paginationCurrentPage"
                          :per-page="parseInt(this.entriesPerPage)"
                          :limit="7"
                          class="mx-auto d-flex"></b-pagination>
            <select-box
                    class="my-0 mr-5 select-box-entries"
                    label="Entries"
                    :selected="entriesPerPage"
                    :options="{5: '5', 10: '10', 25: '25', 50: '50'}"
                    @changed="setEntriesPerPage"></select-box>
        </div>
    </section>
</template>

<script>
    import _ from 'underscore';
    import {mapState} from 'vuex';

    import SearchResultCard from '@/components/partials/SearchResultCard.vue';
    import CheckboxToggle from '@/components/partials/CheckboxToggle.vue';
    import moment from "moment";
    import SelectBox from '@/components/partials/SelectBox.vue';

    export default {
        name: "SearchPaginationList",
        components: {
            SearchResultCard,
            CheckboxToggle,
            SelectBox
        },
        data() {
            return {
                isMobile: null,
                paginationCurrentPage: 1,
                paginationCurrentTotal: 0,
                showValue: true,
                currentItems: [],
                shownLists: [],
                blurClass: '',
            }
        },
        computed: {
            ...mapState({
                sortStrategy: state => state.searchQuery.sortStrategy,
                searchFilters: state => state.searchQuery.searchFilters,
                filterSubjects: state => state.searchQuery.filterSubjects,
                filterYears: state => state.searchQuery.filterYears,
            }),
            showPro: {
                set: function(v) {
                    this.showValue = v;
                    if (this.isMobile) {
                        this.shownLists = [+v];
                        this.paginationCurrentPage = 1;
                    }
                },
                get() {
                    return !this.showValue;
                }
            },
            columnClass() {
                let portion = Math.ceil(12 / this.itemsFiltered.length);
                return `col-lg-${portion}`;
            },
            itemsFiltered() {
                return this.setItemsFiltered(parseInt(this.entriesPerPage));
            },
            entriesPerPage: {
                get() {
                    return this.$store.state.searchQuery.entriesPerPage
                },
                set(v) {
                    this.$store.commit('searchQuery/updateEntriesPerPage', v);
                },
            }
        },
        watch: {
            itemsFiltered() {
                if (document.getElementById('results') && window.scrollY > document.getElementById('results').offsetTop) {
                    this.blurClass = 'blurred';
                    let offSet = this.isMobile ? -20 : -20;
                    this.$scrollTo('#results', 750, {offset: offSet});
                    setTimeout(() => {
                        this.currentItems = this.itemsFiltered;
                        this.blurClass = '';
                    }, 800);
                } else {
                    this.currentItems = this.itemsFiltered;
                }
            },
            entriesPerPage(v) {
                this.currentItems = this.setItemsFiltered(parseInt(this.entriesPerPage));
            }
        },
        props: {
            items: Array,
            textHandle: String
        },
        methods: {
            handleResize() {
                const isMobile = !window.matchMedia("(min-width: 992px)").matches;
                if (this.isMobile !== isMobile) {
                    this.isMobile = isMobile;
                    this.shownLists = isMobile ? [0] : [0, 1];
                    this.paginationCurrentPage = 1;
                    this.$data.showPro = true;
                }
            },
            setItemsFiltered(entriesPerPage) {
                let itemsFiltered = [...this.$props.items];
                let length = 0;
                let self = this;
                const sortStrategy = this.sortStrategy;
                const searchFilters = this.searchFilters;
                const filterSubjects = this.filterSubjects;
                const filterYears = this.filterYears;

                
                itemsFiltered = itemsFiltered
                    .filter((row, index) => {
                        return self.shownLists.indexOf(index) > -1;
                    });

                itemsFiltered.forEach((e, i) => {

                    let children = [...e];


                    if(searchFilters.length) {
                        children = children.filter((row) => {
                            return searchFilters.indexOf (row.source) > -1;
                        });
                    }

                    if (filterSubjects.length) {
                        children = children.filter((row) => {
                            return filterSubjects.some((subject) => row.subjects.includes(subject));
                        });
                    }

                    if (filterYears.length) {
                        children = children.filter((row) => {
                            return filterYears.includes(moment(row.timestamp).year().toString());
                        });
                    }

                    length = Math.max(length, children.length);

                    children = children.sort((a, b) => {
                        if (sortStrategy === "timestamp"){
                            if (a[sortStrategy] < b[sortStrategy]) return 1;
                            if (a[sortStrategy] > b[sortStrategy]) return -1;
                        }
                        else {
                            let a_confidence;
                            let b_confidence;
                            a_confidence = a["sortConfidence"];
                            b_confidence = b["sortConfidence"];
                            if (a_confidence < b_confidence) return 1;
                            if (a_confidence > b_confidence) return -1;
                        }
                        return 0;
                    });

                    children = children.filter((row, index) => {
                        let start = (this.paginationCurrentPage - 1) * entriesPerPage;
                        let end = this.paginationCurrentPage * entriesPerPage;
                        if (index >= start && index < end) return true;
                    });
                    itemsFiltered[i] = children;
                });
                this.paginationCurrentTotal = length;
                return itemsFiltered;
            },
            setEntriesPerPage(v) {
                this.entriesPerPage = v;
            },
        },
        mounted() {
            this.handleResize();
            this.$nextTick(function() {
                window.addEventListener('resize', _.debounce(this.handleResize, 100))
            });
        },
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    ul {
        padding: 0;
        margin: 0;
    }
    .select-box-entries {
        background-color: $primary;
        padding: 3px 7px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
</style>
