import Vue from "vue";
import _, { forEach, result } from "underscore";
import { subjects } from "../subjects";
import moment from "moment";

export default {
    getResults(params) {
        const paramsDefault = {
            topic: "",
            predictStance: true,
            computeAttention: true,
            numDocs: 35,
            sortBy: "argumentConfidence",
            beginDate: "", // has to be set to empty value, because otherwise these parameters would be removed later
            endDate: "",
            index: ["springer", "tuprints", "mdpi"],
            subjects: [],
            language: "x",
        };

        const indexMap = {
            springer: ["springerapi-x"],
            tuprints: ["tuprintsoai-en", "tuprintsoai-de"],
            mdpi: ["mdpi-en"],
        };

        // const language = params.language;

        params = Object.assign({}, paramsDefault, params);

        // remove unknown keys
        params = _.omit(params, function (value, key, object) {
            return !paramsDefault.hasOwnProperty(key);
        });

        // const indexes = Array.from(params.index);
        // now using default indexes after removing select box
        let indexes = [];
        paramsDefault.index.forEach((i) => {
            indexes = indexes.concat(indexMap[i]);
        });

        let promises = [];

        // Function to split the index string by the last hyphen
        function splitIndexString(indexString) {
            const lastHyphenIndex = indexString.lastIndexOf("-");
            if (lastHyphenIndex === -1) {
                return { indexName: indexString, language: "" };
            }
            const indexName = indexString.substring(0, lastHyphenIndex);
            const language = indexString.substring(lastHyphenIndex + 1);
            return { indexName, language };
        }

        indexes.forEach((aindex) => {
            let params_copy = Object.assign({}, params);
            const { indexName, language } = splitIndexString(aindex);
            params_copy.index = indexName;
            params_copy.subjects = params.subjects;

            params_copy.language = language;

            // For springer, we need strict topic search and fewer docs
            /*if (aindex == "springer") {
                    params_copy.strictTopicSearch = true;
                    params_copy.numDocs = 5
                }

                // For turpints, we need more docs
                if (aindex == "tuprints") {
                    params_copy.strictTopicSearch = true;
                    params_copy.numDocs = 200
                }*/

            let request = {
                data: params_copy,
                // segments: {language: language}
            };

            promises.push(
                Vue.$api
                    .searchResults(request)
                    .then((response) => {
                        let responseData = JSON.parse(
                            JSON.stringify(response.data)
                        );
                        responseData.index = request.data.index;
                        return responseData;
                    })
                    .catch((error) => {
                        console.error(error); // Optional: Log the error for debugging purposes
                        return {
                            error: "No documents found. Please try another query or check the server response.",
                            metadata: {},
                            sentences: [],
                        };
                    })
            );
        });

        return Promise.all(promises).then((values) => {
            // Take values from 1st result
            // TODO: Maybe think of a better strategy
            // Find results which are not empty
            let i = 0;
            let error = true;
            let results;
            for (i = 0; i < values.length; i++) {
                const md = Object.assign({}, values[i].metadata);
                if (values[i].sentences && values[i].sentences.length) {
                    results = {
                        metadata: md,
                        sentences: values[i].sentences.map((sentence) => {
                            return { ...sentence, index: values[i].index };
                        }),
                    };
                    error = false;
                    break;
                }
            }

            if (!error) {
                // Start from second element
                for (let j = i + 1; j < values.length; j++) {
                    let aresults = values[j];

                    // Check if there are actually sentences in current result
                    if (aresults.sentences && aresults.sentences.length) {
                        results.metadata.numDocs += aresults.metadata.numDocs;
                        results.metadata.timeArgumentPrediction +=
                            aresults.metadata.timeArgumentPrediction;
                        results.metadata.timeAttentionComputation +=
                            aresults.metadata.timeAttentionComputation;
                        results.metadata.timeIndexing +=
                            aresults.metadata.timeIndexing;
                        results.metadata.timeLogging +=
                            aresults.metadata.timeLogging;
                        results.metadata.timePreprocessing +=
                            aresults.metadata.timePreprocessing;
                        results.metadata.timeStancePrediction +=
                            aresults.metadata.timeStancePrediction;
                        results.metadata.timeTotal +=
                            aresults.metadata.timeTotal;
                        results.metadata.totalArguments +=
                            aresults.metadata.totalArguments;
                        results.metadata.totalClassifiedSentences +=
                            aresults.metadata.totalClassifiedSentences;
                        results.metadata.totalContraArguments +=
                            aresults.metadata.totalContraArguments;
                        results.metadata.totalNonArguments +=
                            aresults.metadata.totalNonArguments;
                        results.metadata.totalProArguments +=
                            aresults.metadata.totalProArguments;
                        results.sentences = results.sentences.concat(
                            aresults.sentences.map((sentence) => {
                                return { ...sentence, index: aresults.index };
                            })
                        );
                    }
                }

                const sentencesOrdered = {
                    pro: [],
                    contra: [],
                };

                const sentencesByDocumentWithMeta = {};
                const documents = {};
                const sources = {};
                const responseSubjects = {};
                const years = {};

                if (results.sentences && results.sentences.length) {
                    results.sentences.forEach((sentence, i) => {
                        sentence.id = i;
                        /*
                        const w = [];

                        // const min = Math.min(...sentence.weights);
                        // const max = Math.max(...sentence.weights);

                        // sentence.sentencePreprocessed.split(' ')
                        //     .forEach((e, i) => {
                        //         const weight = ~~((((sentence.weights[i] - min) / (max - min)) * 9) + 1);

                        //         w.push(`<span class="word-weight-${weight}">${e}</span>`);
                        //     });

                        sentence.sentencePreprocessed.split(' ')
                            .forEach((e, i) => {
                                w.push(`<span>${e}</span>`);
                            });
                        sentence.sentenceWeighted = w.join(' ');
*/
                        //add truncated stance label
                        sentence.stanceLabelDisplay =
                            sentence.stanceLabel.substring(0, 3);

                        sentencesOrdered[sentence.stanceLabel].push(sentence);

                        documents[sentence.url] = sentence.url
                            .replace(/(^\w+:|^)\/\//, "")
                            .replace(/^www\./, "");

                        sentence.pdf = sentence.source;
                        sentence.pdfmeta = sentence.url;
                        sentence.source = Object.keys(indexMap).find(
                            (idx) =>
                                indexMap[idx].find(
                                    (idxString) =>
                                        idxString.indexOf(sentence.index) > -1
                                ) !== undefined
                        );
                        // if (sentence.source === 'springer') {
                        //     sentence.credit = sentence.publicationName
                        // } else {
                        //     sentence.credit = sentence.creator
                        // }
                        // sentence.credit = sentence.header
                        sources[sentence.source] = sentence.source;
                        const year = moment(sentence.timestamp).year();
                        years[year] = year;
                        // add subjects
                        sentence.subjects.forEach((subject) => {
                            responseSubjects[subject] = subjects[subject];
                        });

                        if (!sentencesByDocumentWithMeta[sentence.url]) {
                            sentencesByDocumentWithMeta[sentence.url] = {
                                document: {
                                    timestamp: sentence.timestamp,
                                    source: sentence.source,
                                    title: sentence.header,
                                    url: sentence.url,
                                    sentenceCount: {
                                        total: 0,
                                        pro: 0,
                                        contra: 0,
                                    },
                                },
                                sentences: {
                                    pro: [],
                                    contra: [],
                                },
                            };
                        }
                        sentencesByDocumentWithMeta[sentence.url].sentences[
                            sentence.stanceLabel
                        ].push(sentence);
                        sentencesByDocumentWithMeta[sentence.url].document
                            .sentenceCount.total++;
                        sentencesByDocumentWithMeta[sentence.url].document
                            .sentenceCount[sentence.stanceLabel]++;
                    });

                    results.documents = documents;
                    if (!results.metadata.numDocs)
                        results.metadata.numDocs = Object.keys(
                            results.documents
                        ).length;
                    results.sources = sources;
                    results.years = years;
                    const sortedResponseSubjects = {};
                    // return subjects in the order they are defined in subjects.js
                    Object.keys(subjects).forEach((subject) => {
                        if (responseSubjects[subject])
                            sortedResponseSubjects[subject] =
                                responseSubjects[subject];
                    });
                    results.subjects = sortedResponseSubjects;
                    results.sentencesOrdered = Object.values(sentencesOrdered);

                    results.sentencesByDocumentWithMeta = Object.values(
                        sentencesByDocumentWithMeta
                    ).sort((a, b) => {
                        if (
                            a.document.sentenceCount.total <
                            b.document.sentenceCount.total
                        )
                            return 1;
                        if (
                            a.document.sentenceCount.total >
                            b.document.sentenceCount.total
                        )
                            return -1;
                        return 0;
                    });
                }

                return results;
            }
            // All results where empty
            else {
                return values[0];
            }
        });
    },
};
