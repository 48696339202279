<template>
    <footer class="footer">
        <div class="container">
            <!--
            <div class="copyright text-md-center">© Copyright 2021 Technische Universität Darmstadt</div>
            -->
            <b-navbar toggleable="md" type="" variant="" class="container border-top border-primary">

                <b-navbar-nav class="mx-lg-auto">
                    <!-- <b-nav-item to="/terms">Nutzungsbedingungen/Terms of Use</b-nav-item> -->
                    <b-nav-item to="/">Home</b-nav-item>
                    <b-nav-item to="/background">System Background</b-nav-item>
                    <b-nav-item href="https://www.ulb.tu-darmstadt.de" target="_blank">ULB Home</b-nav-item>
                    <b-nav-item to="/privacy">Privacy</b-nav-item>
                    <b-nav-item to="/legal">Legal Notice</b-nav-item>
                    <b-nav-item href="https://www.ulb.tu-darmstadt.de/kontakt/lage/orientierung_1.en.jsp" target="_blank">Contact</b-nav-item>
                </b-navbar-nav>
            </b-navbar>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "AppFooter"
    }
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: $white;
        z-index: 10 !important;
        padding: 0 8px;


        .copyright {
            margin-top: 15px;
            margin-bottom: 30px;
            color: $gray;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
        }

        .navbar {
            padding: 20px 0;
        }

        a.nav-link {
            color: $dark-green;
            font-size: 15px;
            font-family: signika, helvetica, arial, sans-serif;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
                margin: 0 8px;
            }
        }

        a.nav-link:hover {
            color: $primary !important;
        }
    }
</style>
