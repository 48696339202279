<template>
    <section>
        <div class="row">
            <div class="col-md-12" v-for="(list, index) in itemsFiltered" :key="list.document.url">
                <app-collapse :doc="list.document.url" :documentInfo="list.document" :show.sync="collapse">
                    <div :slot="list.document.url">
                        <div class="row">
                            <div class="col-md-6" v-for="(stance, index) in list.sentences" :key="index">
                                <ul>
                                    <search-result-card
                                            v-for="sentence in stance"
                                            v-bind:key="sentence.id"
                                            v-bind:sentence="sentence"
                                            v-bind:content="sentence.text"
                                    ></search-result-card>
                                </ul>
                            </div>
                        </div>
                    </div>
                </app-collapse>
            </div>
        </div>
        <div class="row">
            <b-pagination size="md"
                          :total-rows="paginationCurrentTotal"
                          v-model="paginationCurrentPage"
                          :per-page="parseInt(this.entriesPerPage)"
                          :limit="7"
                          class="mx-auto d-flex"></b-pagination>
            <select-box
                    class="my-0 mr-2 select-box-entries"
                    label="Entries"
                    :selected="entriesPerPage"
                    :options="{5: '5', 10: '10', 25: '25', 50: '50'}"
                    @changed="setEntriesPerPage"></select-box>
        </div>
    </section>
</template>

<script>
    import {mapState} from 'vuex';

    import SearchResultCard from '@/components/partials/SearchResultCard.vue';
    import CheckboxToggle from '@/components/partials/CheckboxToggle.vue';
    import moment from "moment";
    import SelectBox from '@/components/partials/SelectBox.vue';

    export default {
        name: "SearchPaginationDocuments",
        components: {
            SearchResultCard,
            CheckboxToggle,
            SelectBox,
        },
        data() {
            return {
                paginationCurrentPage: 1,
                paginationCurrentTotal: 0,
                showValue: true,
                currentItems: [],
                collapse: false,
            }
        },
        props: {
            items: Array,
            textHandle: String
        },
        computed: {
            ...mapState({
                sortStrategy: state => state.searchQuery.sortStrategy,
                searchFilters: state => state.searchQuery.searchFilters,
                filterSubjects: state => state.searchQuery.filterSubjects,
                filterYears: state => state.searchQuery.filterYears,
            }),
            itemsFiltered() {
                return this.setItemsFiltered(parseInt(this.entriesPerPage));
            },
            entriesPerPage: {
                get() {
                    return this.$store.state.searchQuery.entriesPerPage
                },
                set(v) {
                    this.$store.commit('searchQuery/updateEntriesPerPage', v);
                },
            }
        },
        watch: {
            itemsFiltered: {
                handler(newVal, oldVal) {
                    // Check if the itemsFiltered array has changed in length or content
                    if (newVal.length !== oldVal.length ||
                        newVal.some((item, index) => item.document !== oldVal[index].document)) {
                        // If so, toggle the collapse state only if there are no sentences left
                        this.collapse = newVal.some(item => item.sentences.pro.length === 0 && item.sentences.contra.length === 0);
                    }

                    // Check if the 'results' element is in the viewport
                    if (document.getElementById('results') && window.scrollY > document.getElementById('results').offsetTop) {
                        // If so, initiate a blur effect and scroll to the 'results' element
                        this.blurClass = 'blurred';
                        let offSet = this.isMobile ? -20 : -20;
                        this.$scrollTo('#results', 750, {offset: offSet});
                        // After a delay, update the current items and remove the blur effect
                        setTimeout(() => {
                            this.currentItems = this.itemsFiltered;
                            this.blurClass = '';
                        }, 800);
                    } else {
                        // If 'results' element is not in the viewport, simply update the current items
                        this.currentItems = this.itemsFiltered;
                    }
                },
                deep: true
            },
            entriesPerPage() {
                this.currentItems = this.setItemsFiltered(parseInt(this.entriesPerPage));
            }
        },
        methods: {
            setItemsFiltered(entriesPerPage) {
                let itms = [...this.$props.items];

                const searchFilters = this.searchFilters;
                const filterSubjects = this.filterSubjects;
                const filterYears = this.filterYears;


                itms = itms.map((row) => {
                    let sourceMatches = searchFilters.length ? searchFilters.includes(row.document.source) : true;

                    let proSentences = [...row.sentences.pro];
                    let contraSentences = [...row.sentences.contra];

                    if (filterSubjects && filterSubjects.length) {
                        proSentences = proSentences.filter(sentence => filterSubjects.some(subject => sentence.subjects.includes(subject)));
                        contraSentences = contraSentences.filter(sentence => filterSubjects.some(subject => sentence.subjects.includes(subject)));
                    }

                    if (filterYears && filterYears.length) {
                        proSentences = proSentences.filter(sentence => filterYears.includes(moment(sentence.timestamp).year().toString()));
                        contraSentences = contraSentences.filter(sentence => filterYears.includes(moment(sentence.timestamp).year().toString()));
                    }

                    let proMatches = proSentences.length > 0;
                    let contraMatches = contraSentences.length > 0;

                    if (sourceMatches && (proMatches || contraMatches)) {
                        return {
                            ...row,
                            sentences: {
                                pro: proSentences,
                                contra: contraSentences
                            },
                            document: {
                                ...row.document,
                                sentenceCount: {
                                    pro: proSentences.length,
                                    contra: contraSentences.length,
                                    total: proSentences.length + contraSentences.length
                                }
                            }
                        };
                    }
                }).filter(Boolean);

                this.paginationCurrentTotal = itms.length;

                itms.forEach((e, i) => {
                    let lists = {...e.sentences};
                    
                    for (let k in lists) {
                        lists[k] = lists[k].sort((a, b) => {
                            if (a.confidence < b.confidence) return 1;
                            if (a.confidence > b.confidence) return -1;
                            return 0;
                        });
                    }

                    itms[i].sentences = lists;
                });

                itms = itms.filter((row, index) => {
                    let start = (this.paginationCurrentPage - 1) * entriesPerPage;
                    let end = this.paginationCurrentPage * entriesPerPage;
                    if (index >= start && index < end) return true;
                });

                return itms;
            },
            setEntriesPerPage(v) {
                this.entriesPerPage = v;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    ul {
        padding: 0;
        margin: 0;
    }
    .select-box-entries {
        background-color: $primary;
        padding: 3px 7px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
</style>
